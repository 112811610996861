import React, {useState} from 'react';
import Amplify, { Auth } from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import {ApolloProvider} from "react-apollo";

import './App.css';
import duke_logo from "./assets/images/logoDukeEnergy.svg";
import lime_logo from "./assets/images/Lime_Willdan_RGB@2x.png";
import {Form} from "./components/form";
import {Unable} from "./components/unable";

Amplify.configure({
    Auth: {
        identityPoolId: process.env.REACT_APP_IDENTITYPOOL_ID,
        region: process.env.REACT_APP_APPSYNC_REGION,
        mandatorySignIn: false,
    },
});

const client: any = new AWSAppSyncClient({
    url: process.env.REACT_APP_APPSYNC_ENDPOINT || "",
    region: process.env.REACT_APP_APPSYNC_REGION || "us-east-1",
    auth: {
        type: AUTH_TYPE.AWS_IAM,
        credentials: () => Auth.currentCredentials(),
    },
});

function App() {

    const [isValid, setIsValid] = useState(true);

    const accountValid = () => (valid: boolean) => {
        setIsValid(valid);
    };

  return (
      <ApolloProvider client={client}>
        <div className="App">
            <div id="header">
                <img src={duke_logo} alt="lime logo" width="400px" height="100px"/>
                <p>Thank you for choosing to participate in the Small Business Energy Saver program.
                    To participate, you must meet certain program qualifications.</p>
            </div>

            { isValid ?
                <div id="main-content">
                    <p><b>Step 1</b> – Does my business qualify for the Small Business Energy Saver program?
                        To determine eligibility, please provide the following information below.</p>
                    <Form accountValid={accountValid()}/>
                </div>
                :
                <div id="main-content">
                    <Unable accountValid={accountValid()}/>
                </div>
            }
            <p>&nbsp;</p>
            <div id="footer">
                <span>
                    <img src={lime_logo} alt="lime logo" width="120px"/><br/>
                    <span className="copyright">&copy; {(new Date().getFullYear())} Willdan</span>
                </span>
                <p >Prefer to speak to a Customer Service Representative? Call <a href="tel:855-776-4723">855-776-4723</a> If
                    you are already a program participant and have questions related to construction and/or
                    warranty-related items, please call <a href="tel:855.232.1042">855.232.1042</a> or
                    visit <a href="http://www.sbeswarranty.com">www.sbeswarranty.com</a></p>
            </div>
        </div>
      </ApolloProvider>
  );
}

export default App;
