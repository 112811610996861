import React, {useState}  from 'react';
import bill_example from "../assets/images/Sample_Bill_Image_Account-highlighted.png";
import { useMutation } from '@apollo/react-hooks';
import {MutationCreateVerificationData} from '../api/'
import axios from 'axios';
var FontAwesome = require('react-fontawesome');


type Props = {
    accountValid: any
}

function Form(props: Props) {

    const [createVerificationData, { data }] = useMutation(MutationCreateVerificationData);
    const [inputs, setInputs] = useState({ zipCode: '', accountNumber: ''});
    const [loading, setLoading] = useState(false);

    const diUrl = process.env.REACT_APP_DI_ENDPOINT;
    const programID = process.env.REACT_APP_PROGRAM_ID;
    const NO_URL = "NOT EXIST";

    console.log(data);

    const submitForm = (evt: any) => {
        evt.preventDefault();
        setLoading(true);

        axios({
            method: 'post',
            url: `${diUrl}?accountNumber=${inputs.accountNumber}&zipCode=${inputs.zipCode}`,
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'programId': programID
            },
        }).then(function (response) {
            if(response.data){
                console.log(response.data);
                makeMutation(response.data);
            } else {
                console.log(NO_URL, response);
                makeMutation(NO_URL);
            }
        }).catch(function (error) {
            setLoading(false);
            props.accountValid(false);
        });
    };

    const makeMutation = (url: string) => {
        createVerificationData({ variables: {
            accountNumber: inputs.accountNumber,
            zipCode: inputs.zipCode,
            url: url
        } })
        .then((res: any) => {
            if(url !== NO_URL){
                window.location.href = url;
            } else {
                setLoading(false);
                props.accountValid(false);
            }
        }).catch(() => {
            setLoading(false);
            props.accountValid(false);
        });
    };

    const handleInputChange = (evt: any) => {
        evt.persist();
        setInputs(inputs => ({...inputs, [evt.target.name]: evt.target.value}));
    };


    return (
        <div id="form-container">
            <form onSubmit={submitForm}>
                <label>
                    Account Number
                    <input type="text" name="accountNumber" onChange={handleInputChange}
                           value={inputs.accountNumber}/>
                </label>
                <label>
                    Service Address Zip Code
                    <input type="text" name="zipCode" onChange={handleInputChange}
                           value={inputs.zipCode}/>
                </label>

                <br/>
                <button type="submit"
                        disabled={loading || inputs.accountNumber.length === 0 || inputs.zipCode.length === 0}>
                    { loading ?  <FontAwesome name='spinner' spin/> : "Search" }
                </button>

            </form>
            <div>
                <h3>You can find your Account Number and Service Address Zip Code
                    at the top of your Duke Energy utility bill</h3>
                <img src={bill_example} alt="bill example" className="bill-img"/>
            </div>
        </div>
    )
}

export { Form };
