import React  from 'react';

type Props = {
    accountValid: any
}
function Unable(props: Props) {

    const goBack = () => {
        props.accountValid(true);
    };



    const redirect_to = "https://www.duke-energy.com/business/products/small-business-energy-saver/energy-assessment";

    return (
        <div>
            <p>We’re sorry, we were unable to locate your account with Duke Energy. Please call our Small Business Energy
                Saver program customer service for further assistance
                or <a href={redirect_to} target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold' }}>click here</a> to request an assessment.
            </p>
            <h1><a href="tel:1-855-776-4723">1-855-776-4723</a></h1>
            <button type="button" onClick={goBack}>OK</button>
        </div>
    )
}

export { Unable };
