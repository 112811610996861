import gql from "graphql-tag";

// import { VerificationData } from '.'

const MutationCreateVerificationData = gql`
    mutation CreateVerificationData(
        $accountNumber: String!
        $zipCode: String!
        $url: String!
    ){
        createVerificationData(
            accountNumber: $accountNumber
            zipCode: $zipCode
            url: $url
        ){
            accountNumber
            zipCode
            url
        }
    }
`;
export { MutationCreateVerificationData}
